import { BrowserModule } from "@angular/platform-browser";
import { EffectsModule } from "@ngrx/effects";
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgModule, Injector, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import {
  IconsModule,
  AlertService,
  FluxNotificationModule,
  DropdownModule,
  DrilldownModule,
  ButtonModule,
  GroupModule,
  BsDateRangePickerModule,
  SplitButtonModule,
  InputGroupModule,
  LoaderModule,
  DisabledModule,
  InputModule,
  ContentModule,
  ToolbarModule,
  MasterDetailModule,
  BarItemModule,
  NavBarModule,
  LayoutModule,
  CheckboxModule
} from "@eplan/flux";
import { StoreModule } from "@ngrx/store";
import { ConfigurationService, ConfigurationServiceModule } from "@eplan/ngx-configuration-service";
import { AppComponent } from "./components/app/app.component";
import { EplanPlattformService } from "app/services/eplan-plattform.service";
import { AuthorizationService } from "app/services/auth/authorization.service";
import { HttpClientInterceptorService } from "app/services/http-client-interceptor.service";
import { HostService } from "app/services/host.service";
import { UrlService } from "app/services/url.service";
import { UserIdleService } from "app/services/user-idle.service";
import { LanguageService } from "@eplan/language";
import { AuthModule } from "@eplan/auth";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { OneHeaderModule } from "@eplan/oneheader";
import { AuthGuardService } from "app/services/auth/auth-guard.service";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { callCreateTranslateLoader, translateInitConfig, TranslateInitModule } from "@eplan/translateinit";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DecimalPipe } from "@angular/common";
import { ReportsService } from "./services/reports.service";
import { QueryService } from "./services/query.service";
import { EplanAnalyticsService } from "./services/eplananalytics.service";
import { ExternalUserService } from "./services/external-user.service";
import { GlobalErrorHandlerService } from "./services/global-error-handler.service";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ReportViewerComponent } from "app/components/reportviewer/reportviewer.component";
import { DashboardComponent } from "app/components/dashboard/dashboard.component";
import { UsersComponent } from "app/components/users/users.component";
import { MansFilterPipe } from "./tools/mansfilter.pipe";
import { SignalRService } from "./services/signalr.service";
import { BackendEndpointsErrorHandler } from "./services/backend-endpoints-error-handler.service";
import { SharedModule } from "app/shared/shared.module";
import { LoggerService } from "app/services/logger.service";
import { FeaturesService } from "app/services/features.service";
import { ManufacturerFilterComponent } from "app/shared/components/filters/manufacturer-filter/manufacturer-filter.component";
import { EdsFilterComponent } from "app/shared/components/filters/eds-filter/eds-filter.component";
import { GlobalSearchFilterComponent } from "app/shared/components/filters/global-search-filter/global-search-filter.component";
import { DateRangeFilterComponent } from "app/shared/components/filters/date-range-filter/date-range-filter.component";
import { FiltersPanelComponent } from "app/shared/components/filters/filters-panel/filters-panel.component";
import { DataExportComponent } from "app/shared/components/filters/data-export/data-export.component";
import { BookmarksComponent } from "app/shared/components/filters/bookmarks/bookmarks.component";
import { RegionFilterComponent } from "app/shared/components/filters/region-filter/region-filter.component";
import { ProductGroupTreeFilterComponent }
  from "app/shared/components/filters/product-group-tree-filter/product-group-tree-filter.component";
import { ManufacturerCatalogFilterComponent }
  from "app/shared/components/filters/manufacturer-catalog-filter/manufacturer-catalog-filter.component";
import { NotAuthorizedComponent } from "app/shared/components/not-authorized/not-authorized.component";
import { ShellComponent } from "app/shared/components/router/shell/shell.component";
import { CacheBustingModule } from "@eplan/cache-busting";
import { environment } from "environments/environment";
import { RequestPlatformComponent } from "./components/request-platform/request-platform.component";
import { ManufacturerComponent } from "./components/manufacturer/manufacturer.component";
import { PartsComponent } from "./components/parts/parts.component";
import { NoAuthGuardService } from "./services/auth/no-auth-guard.service";
import { eplanAuthSettings, oidcClientSettings, oidcLoggerSettings } from "./services/configuration/oidc-client-configuration";
import { EplanWindow } from "./models/external-libs/eplan-window";
import { AnalyticsConfig, AnalyticsModule } from "@eplan/flux/analytics";
import { FluxCoreModule } from "@eplan/flux/core";

export const initFactory = (hostService: HostService) =>
  () => new Promise(resolve => {
    hostService.initialize().then(() => {
      resolve(null);
    });
  });

const eplanWindow = window as EplanWindow;

@NgModule({
  declarations: [
    AppComponent,
    BookmarksComponent,
    DataExportComponent,
    DashboardComponent,
    EdsFilterComponent,
    FiltersPanelComponent,
    GlobalSearchFilterComponent,
    ManufacturerCatalogFilterComponent,
    UsersComponent,
    ManufacturerFilterComponent,
    ProductGroupTreeFilterComponent,
    RegionFilterComponent,
    ReportViewerComponent,
    ShellComponent,
    DateRangeFilterComponent,
    NotAuthorizedComponent,
    MansFilterPipe,
    RequestPlatformComponent,
    ManufacturerComponent,
    PartsComponent
  ],
  imports: [
    ConfigurationServiceModule.provideConfigServiceEntries(eplanWindow.services, ""),
    CacheBustingModule.forRoot({ buildId: environment.version }),
    AppRoutingModule,
    EffectsModule.forRoot([]),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    FluxCoreModule.forRoot({}),
    FluxNotificationModule,
    DropdownModule,
    DrilldownModule,
    ButtonModule,
    GroupModule,
    BsDateRangePickerModule,
    SplitButtonModule,
    InputGroupModule,
    LoaderModule,
    DisabledModule,
    InputModule,
    ContentModule,
    ToolbarModule,
    MasterDetailModule,
    BarItemModule,
    NavBarModule,
    LayoutModule,
    CheckboxModule,
    OneHeaderModule,
    FormsModule,
    IconsModule.forRoot({
      lazyLoadSVGs: true
    }),
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: callCreateTranslateLoader,
        deps: [HttpClient, translateInitConfig],
      },
      extend: true,
      isolate: false,
    }),
    TranslateInitModule.forRoot({
      explicitInit: false,
      sources: [
        {
          folder: "assets/translation/ReportCenter/",
          filename: "/CS11-ReportCenter.json",
        },
        {
          folder: "/assets/translations/common-translations/",
          filename: "/common-translations.json"
        }
      ],
      availableLanguages: ["de-DE", "en-US"],
    }),
    StoreModule.forRoot({}),
    AuthModule.forRoot(AuthorizationService, {
      eplanOidcClientSettings: oidcClientSettings,
      eplanAuthSettings,
      oidcLoggingSettings: oidcLoggerSettings,
    }),
    SharedModule,
    DragDropModule,
    AnalyticsModule.forRoot({
      config: { enabled: false } as AnalyticsConfig,
      loggingServiceUrl: eplanWindow.services.loggingService,
      tags: {
        loggingConfigId: eplanWindow.configuration.serviceName,
        productName: eplanWindow.configuration.serviceName,
        environment: eplanWindow.configuration.environment,
        appName: eplanWindow.configuration.serviceName,
      },
    }),
  ],
  providers: [
    AlertService,
    ExternalUserService,
    AuthorizationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptorService,
      multi: true
    },
    AuthGuardService,
    EplanPlattformService,
    HostService,
    UrlService,
    UserIdleService,
    LoggerService,
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: initFactory,
      deps: [HostService, Injector],
      multi: true
    },
    LanguageService,
    DecimalPipe,
    FeaturesService,
    ReportsService,
    QueryService,
    EplanAnalyticsService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    },
    SignalRService,
    BackendEndpointsErrorHandler,
    NoAuthGuardService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(private loggerService: LoggerService) {
  }
}
